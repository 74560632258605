<!-- @format -->

<template>
	<div>
		<div v-if="!!loading"></div>
		<div v-if="!loading">
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-header">Select customer to preview consumption</div>
			</div>
			<ve-table
				:columns="columns"
				:rows="rows"
				:searchedOptions="true"
				:onRowDoubleClick="onRowDoubleClick"
				:onCellClick="onCellClick"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success">Select</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</ve-table>
		</div>
	</div>
</template>

<script>
import Store from '@/store'
import { computed, reactive, ref } from '@vue/composition-api'
import storeSearchCustomer from '@/_srcv2/views/pages/admin-reports/time-interval-customer-consumption/subcomponents/useCustomerSearchState.js'
import GetCustomersQuery from '@/_srcv2/views/pages/admin-reports/time-interval-customer-consumption/GetCustomersQuery.graphql'
import { useQuery } from '@vue/apollo-composable'

export default {
	name: 'SubSearchCustomer',
	setup() {
		// Set Columns
		const { columnsLabels } = storeSearchCustomer()
		const columns = computed(() => columnsLabels.value)
		// --------------------------------------------------------------------------------------------
		// Set Rows
		const rows = ref([])
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, loading } = useQuery(
			GetCustomersQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		onResult((result) => {
			rows.value = result.data.customers
			console.log('ON RESULT', result.data.customers)
		})
		// --------------------------------------------------------------------------------------------
		// Select customer to get its consumption
		const { selectedCustomerId, selectedCustomerInfo } = storeSearchCustomer()
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				selectedCustomerId.value = params.row.customer_id
				selectedCustomerInfo.value = `${params.row.customer_id} - ${params.row.customer_title} - ${params.row.customer_nickname}`
			}
		}
		const onRowDoubleClick = (params) => {
			selectedCustomerId.value = params.row.customer_id
			selectedCustomerInfo.value = `${params.row.customer_id} - ${params.row.customer_title} - ${params.row.customer_nickname}`
		}
		// ? --------------------------------------------------------------------------------------------
		return {
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			loading,
		}
	},
}
</script>

<style scoped></style>
