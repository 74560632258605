<!-- @format -->

<template>
	<layout-default-new>
		<sub-navbar page="Utilities" />
		<div>
			<HeroBox :title="title" :subtitle="subtitle" :color="color" />
		</div>
		<section>
			<div class="margin-bottom-30" v-if="!selectedCustomer">
				<sub-search-customer />
			</div>
			<div v-if="selectedCustomer">
				<b-field label="Selected Customer" :label-position="labelPosition">
					<b-input :value="selectedCustomerInfo" disabled="true"></b-input>
				</b-field>
			</div>
			<div v-if="selectedCustomer">
				<b-field label="Select beginning date">
					<b-datepicker
						v-model="selectedBeginningDate"
						:first-day-of-week="firstDayOfWeek"
						:show-week-number="showWeekNumber"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<b-field label="Select ending date">
					<b-datepicker
						v-model="selectedEndingDate"
						:show-week-number="showWeekNumber"
						:first-day-of-week="firstDayOfWeek"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<b-button
					class="is-primary margin-top-20"
					type="submit"
					@click="getData"
					>Get Results</b-button
				>
				<b-button
					class="is-success margin-top-20 margin-left-20"
					type="submit"
					@click="resetForm"
					>Clean Form</b-button
				>
			</div>
			<div class="margin-top-30">
				<loading-circle v-if="isWaitingData" />
			</div>
			<div v-if="!!selectedCustomer">
				<b-table
					class="margin-top-20"
					:data="listArr"
					:columns="columns"
					:bordered="true"
					:striped="true"
					:narrowed="true"
					:hoverable="false"
					:loading="false"
					:focusable="false"
					:mobile-cards="true"
				>
					<template #footer v-if="true">
						<div class="has-text-right">
							{{ totalConsumption }}
							<br />
							{{ numberOfStockItemTypes }}
						</div>
					</template>
				</b-table>
			</div>
		</section>
	</layout-default-new>
</template>

<script>
import {
	computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from '@vue/composition-api'
import dateformat from 'dateformat'
import addDays from 'add-days'
import Store from '@/store'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import { useQuery } from '@vue/apollo-composable'
import GetCustomerConsumptionItems from '@/_srcv2/graphql/admin-reports/queries/getCustomerConsuptionItems.query.gql'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import LoadingCircle from '@/_srcv2/components/_shared/loading/LoadingCircle.vue'
import SubSearchCustomer from '@/_srcv2/views/pages/admin-reports/time-interval-customer-consumption/subcomponents/SubSearchCustomer.vue'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
import storeSearchCustomer from '@/_srcv2/views/pages/admin-reports/time-interval-customer-consumption/subcomponents/useCustomerSearchState.js'
export default {
	name: 'TimeIntervalCustomerConsumption',
	components: {
		SubSearchCustomer,
		SubNavbar,
		LoadingCircle,
		HeroBox,
	},
	setup() {
		const labelPosition = ref('on-border')
		const { selectedCustomerId, selectedCustomerInfo } = storeSearchCustomer()
		const selectedCustomer = computed(() => selectedCustomerId.value)
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
		})
		const selectedBeginningDate = ref(new Date())
		watchEffect(() => {
			parameters.beginningDate = dateformat(
				selectedBeginningDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedEndingDate = ref(new Date())
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		const firstDayOfWeek = ref(1)
		// Time Interval Dispatches
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchDispatch, onResult } = useQuery(
			GetCustomerConsumptionItems,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				customer_id: selectedCustomer.value,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		onResult(() => (isWaitingData.value = false))
		const { floatedCurrencyAddedNumber } = useReportNumber()
		// Create Table
		const tableValues = ref([])
		const tableTotals = reactive({
			kindsOfItems: 0,
			totalConsumption: 0,
		})
		const getData = () => {
			isWaitingData.value = true
			tableValues.value = []
			tableTotals.kindsOfItems = 0
			tableTotals.totalConsumption = 0
			refetchDispatch().then((result) => {
				console.log('result from promise', result)
				tableValues.value = result.data.goods_transactions
					.map((item) => {
						return {
							stockId: item.goods_transactions_stock_rel.stock_id,
							itemName: item.goods_transactions_stock_rel.stock_name,
							amount:
								item.goods_transaction_goods_transaction_rel_aggregate.aggregate
									.sum.amount_credit,
							consumptionWorth: floatedCurrencyAddedNumber(
								item.goods_transaction_goods_transaction_rel_aggregate.aggregate
									.sum.line_price_total_credit,
							),
						}
					})
					.filter((item) => item.amount !== 0)
				// tableTotals.kindsOfItems =
				//   result.data.goods_transactions_aggregate.aggregate.count
				tableTotals.totalConsumption = floatedCurrencyAddedNumber(
					result.data.goods_transactions_aggregate.aggregate.sum
						.line_price_total_credit,
				)
				tableTotals.kindsOfItems = tableValues.value.length
			})
		}
		const listArr = computed(() => tableValues.value)
		const columns = [
			{
				field: 'stockId',
				label: 'Stock Id',
				width: '110',
				centered: true,
			},
			{
				field: 'itemName',
				label: 'Item Name',
				width: '350',
			},
			{
				field: 'amount',
				label: 'Amount',
				width: '40',
				centered: true,
			},
			{
				field: 'consumptionWorth',
				label: 'Consumption',
				width: '60',
				numeric: true,
			},
		]
		// const { floatedCurrencyAddedNumber } = useReportNumber()
		const isWaitingData = ref(null)
		const resetForm = () => {
			isWaitingData.value = false
			selectedCustomerId.value = ''
			selectedCustomerInfo.value = ''
			tableValues.value = []
			tableTotals.kindsOfItems = 0
			tableTotals.totalConsumption = 0
			const today = new Date()
			selectedEndingDate.value = today
			selectedBeginningDate.value = addDays(today, -90)
		}
		onMounted(() => {
			resetForm()
		})
		const numberOfStockItemTypes = computed(
			() => `Number of stock item types: ${tableTotals.kindsOfItems}`,
		)
		const totalConsumption = computed(
			() => `Total Consumption: ${tableTotals.totalConsumption}`,
		)
		// ? ----------------------------------------------------------------------------
		const title = ref('Customer Consumption Page')
		const subtitle = ref('Time interval customer consumption report')
		const color = ref('is-info')
		return {
			labelPosition,
			selectedCustomer,
			selectedCustomerInfo,
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			firstDayOfWeek,
			getData,
			listArr,
			columns,
			numberOfStockItemTypes,
			totalConsumption,
			resetForm,
			isWaitingData,
			title,
			subtitle,
			color,
		}
	},
}
</script>

<style scoped>
.form-title {
	font-weight: bolder;
	font-size: 1.5em;
	color: #9b1c1c;
	margin-left: 5px;
	margin-bottom: 20px;
}
.margin-top-20 {
	margin-top: 20px;
}
.loading-tag {
	font-size: 2em;
	font-weight: bolder;
	color: #9b1c1c;
}
</style>
