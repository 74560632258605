// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const customerSearchState = reactive({
	selectedCustomerId: '',
	selectedCustomerInfo: '',
	columnsLabels: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Price Class',
			field: 'customer_price_class',
			width: '75px',
			tdClass: 'center-it',
			thClass: 'center-it',
		},
		{
			label: 'Customer ID',
			field: 'customer_id',
			width: '135px',
			thClass: 'center-it',
		},
		{
			label: 'Customer Title',
			field: 'customer_title',
			thClass: 'center-it',
		},
		{
			label: 'Customer Nickname',
			field: 'customer_nickname',
			thClass: 'center-it',
		},
		{
			label: 'Org.Number',
			field: 'customer_org_num',
			width: '135px',
			thClass: 'center-it',
		},
	],
})

export default function storeSearchCustomer() {
	return {
		...toRefs(customerSearchState),
	}
}
